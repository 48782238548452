exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-archived-happyholidays-js": () => import("./../../../src/pages/archived-happyholidays.js" /* webpackChunkName: "component---src-pages-archived-happyholidays-js" */),
  "component---src-pages-featured-js": () => import("./../../../src/pages/featured.js" /* webpackChunkName: "component---src-pages-featured-js" */),
  "component---src-pages-glossary-js": () => import("./../../../src/pages/glossary.js" /* webpackChunkName: "component---src-pages-glossary-js" */),
  "component---src-pages-happyholidays-js": () => import("./../../../src/pages/happyholidays.js" /* webpackChunkName: "component---src-pages-happyholidays-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-templates-index-js": () => import("./../../../src/templates/index.js" /* webpackChunkName: "component---src-templates-index-js" */),
  "component---src-templates-post-js": () => import("./../../../src/templates/post.js" /* webpackChunkName: "component---src-templates-post-js" */),
  "component---src-templates-tags-js": () => import("./../../../src/templates/tags.js" /* webpackChunkName: "component---src-templates-tags-js" */)
}

