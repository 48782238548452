require("prismjs/themes/prism-tomorrow.css");

export const onRouteUpdate = () => {
  // If analytics.js (segment) is not found, log a warning in dev env only
  if (!window.analytics || typeof window.analytics.page !== "function") {
    if (process.env.NODE_ENV === "development") {
      // console.warn('Unable to locate analytics.js');
    }
    return;
  }

  // If development don't log analytics
  if (process.env.NODE_ENV !== "development") {
    window.analytics.page();
  }
};
